// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Helpers
@import "../helpers";

// Global
@import "global";

// Pages
@import "pages/dashboard";

@import "custom/custom.scss";

@import "pages/applyLeave"