#leave-rule-section {
    .leave-rule-section-collapse-expand-button span::before {
        // content: "\f106";
        content: "\f068";
    }

    .leave-rule-section-collapse-expand-button.collapsed span::before {
        // content: "\f107";
        content: "\f067";
    }

    .leave-rule-section-collapse-expand-button {
        position: absolute;
        right: 30px;
        top: 5px;
    }

    .leave-rule-section-collapse-expand-button span {
        cursor: pointer;
        font-size: 20px;
        color: #000;
        margin-top: 8px;
    }

    .leave_rule_main_list {
        // font-family: monospace;
        font-family: sans-serif;
        list-style-type: upper-roman;
        // list-style-type: none;

        .leave_rule_inner_list {
            list-style-type: decimal;

            .inner-list-elements {
                .leave_rule_list {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 1em;
                }

                .leave_rule_list > li:before {
                    display: inline-block;
                    content: "-";
                    width: 1em;
                    margin-left: -1em;
                }
            }

            .conditions-list {
                list-style-type: none;

                .leave_rule_list {
                    list-style-type: lower-alpha;
                }
            }

            .examples-list {
                list-style-type: none;

                .leave_rule_list {
                    list-style: none;
                }
            }
        }
    }
}
