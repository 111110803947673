// Margin/Padding Helpers
$margin-padding: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50;
@each $mp in $margin-padding {
  .mt-#{$mp} {
    margin-top:#{$mp}px !important;
  }
  .mb-#{$mp} {
    margin-bottom:#{$mp}px !important;
  }
  .ml-#{$mp} {
    margin-left:#{$mp}px !important;
  }
  .mr-#{$mp} {
    margin-right:#{$mp}px !important;
  }

  .pt-#{$mp} {
    padding-top:#{$mp}px !important;
  }
  .pb-#{$mp} {
    padding-bottom:#{$mp}px !important;
  }
  .pl-#{$mp} {
    padding-left:#{$mp}px !important;
  }
  .pr-#{$mp} {
    padding-right:#{$mp}px !important;
  }
}