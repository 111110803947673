/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 6 Feb, 2017, 12:14:00 PM
    Author     : lzpc2
*/

.margin-top-5{
    margin-top: 5px;
}
.margin-bottom-5{
    margin-bottom: 5px;
}

.err-messages{
    color: #d93d3e;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 15px;
}

.text-align-center{ 
    text-align: center; 
}

.float-right{
    float:right;
}

.past-holiday{
    display: block;
    padding: 5px;
    margin: -5px;
    background-color: #EBE5E4;
    border-color: #EBE5E4
}

.dashboard td,th{
    padding-right: 35px;
}

.warning-error{
    color: #F21D2E;
}

