// Dashboard Styles/

#user-occupied-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    text-align: center;

    /* Styling for the popup content */
    .popup-content {
    background-color: #fff;
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    /* Styling for the modal title */
    .popup-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    }

    /* Styling for radio buttons and labels */
    .popup-content input[type="radio"] {
    margin-right: 5px;
    }

    .popup-content label {
    display: inline-block;
    margin-right: 15px;
    color: #555;
    }

    /* Styling for the input field and label */
    #project-name-field label {
    display: block;
    margin-top: 10px;
    color: #555;
    }

    #project-name-field input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    }

    /* Styling for the submit button */
    #submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    }

    #submit-button:hover {
    background-color: #0056b3;
    }

    /* Styling for the close button */
    .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    }

    .close:hover {
    color: #000;
    }
}